.video-container {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.video-text-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 4px;
  word-wrap: break-word;
  font-family: "Montserrat", sans-serif;
  color: white;
}
.video-text-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 3.8rem;
  font-family: "Playfair Display", serif;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.video-container.fade-out .overlay,
.video-container.fade-out .video-text,
.video-container.fade-out .video-element {
  opacity: 0;
}
