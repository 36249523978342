.homeFooter p {
  font-family: "Roboto", sans-serif;
  color: gray;
}
.homeParent {
  position: relative;
  min-height: max-content;
  background-color: rgb(22, 22, 22);
}
.homeFooter {
  position: relative;
  padding: 60px 15px;
}
.homeBottom {
  /* height: 310px; */
  min-height: max-content;
  margin-bottom: 170px;
  color: white;
  text-align: center;
}
.homeBottomContent {
}
.homeBottomContent .subscribe {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 2.7rem;
  margin-bottom: 30px;
}
.homeBottomContentIconsBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 50px;
}

.homeBottomContentIconsBox a {
  color: white;
}

.homeBottomContentIcons {
  font-size: 22px;
  transition: 0.3s all ease;
}
.homeBottomContentIcons:hover {
  transform: scale(1.1);
}
.signUpText {
  font-size: 15px;
  margin-bottom: 21px;
}

.homeSubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.homeSubscribeInput {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: solid 1.2px white;
  border-radius: 4px;
  width: 550px;
  overflow: hidden;
  height: 50px;
  margin: 0 50px 20px 40px;
}
.homeSignUpInput {
  height: 25px;
  padding: 12px;
  background-color: transparent;
  border: 0;
  outline: 0;
  color: white;
  width: 100%;
  min-width: 100px;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.homeInputLabel {
  position: absolute;
  top: 0.9em;
  left: 1em;
  transition: 0.4s ease-out;
  pointer-events: none;
}

.homeInputLabel:focus + .homeInputLabel {
  top: 0.3em;
  font-size: 0.7em;
  color: gray;
}
.filled {
  display: none;
}
.homeSubscribeInput label {
  color: gray;
}
.homeSignUpBtn {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  transition: 0.3s all ease;
  letter-spacing: 1px;
  font-weight: 500;
  height: 47px;
  padding: 8px 32px;
  border: 0;
  outline: 0;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
}
.homeSignUpBtn:hover {
  transform: scale(1.1);
}

.copyright {
  font-size: 14px;
  color: white;
  /* position: absolute;
  bottom: 15px;
  left: 60px; */
}
